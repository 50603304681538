// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* InternalServerError.css */
.error-page {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background-color: #f8f9fa;
    color: #343a40;
    text-align: center;
  }
  
  .error-page h1 {
    font-size: 10rem;
    margin: 0;
  }
  
  .error-page p {
    font-size: 1.5rem;
    margin: 0.5rem 0;
  }
  
  .error-page button {
    padding: 0.75rem 1.5rem;
    font-size: 1rem;
    color: #fff;
    background-color: #007bff;
    border: none;
    border-radius: 0.25rem;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .error-page button:hover {
    background-color: #0056b3;
  }
  `, "",{"version":3,"sources":["webpack://./src/pages/Blog/page_500_/InternalServerError.css"],"names":[],"mappings":"AAAA,4BAA4B;AAC5B;IACI,aAAa;IACb,sBAAsB;IACtB,uBAAuB;IACvB,mBAAmB;IACnB,aAAa;IACb,yBAAyB;IACzB,cAAc;IACd,kBAAkB;EACpB;;EAEA;IACE,gBAAgB;IAChB,SAAS;EACX;;EAEA;IACE,iBAAiB;IACjB,gBAAgB;EAClB;;EAEA;IACE,uBAAuB;IACvB,eAAe;IACf,WAAW;IACX,yBAAyB;IACzB,YAAY;IACZ,sBAAsB;IACtB,eAAe;IACf,sCAAsC;EACxC;;EAEA;IACE,yBAAyB;EAC3B","sourcesContent":["/* InternalServerError.css */\n.error-page {\n    display: flex;\n    flex-direction: column;\n    justify-content: center;\n    align-items: center;\n    height: 100vh;\n    background-color: #f8f9fa;\n    color: #343a40;\n    text-align: center;\n  }\n  \n  .error-page h1 {\n    font-size: 10rem;\n    margin: 0;\n  }\n  \n  .error-page p {\n    font-size: 1.5rem;\n    margin: 0.5rem 0;\n  }\n  \n  .error-page button {\n    padding: 0.75rem 1.5rem;\n    font-size: 1rem;\n    color: #fff;\n    background-color: #007bff;\n    border: none;\n    border-radius: 0.25rem;\n    cursor: pointer;\n    transition: background-color 0.3s ease;\n  }\n  \n  .error-page button:hover {\n    background-color: #0056b3;\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
