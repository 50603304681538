import './TableOfContent.css';
import { useEffect, useState } from 'react';

function TableOfContent() {
  const [headings, setHeadings] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const updateHeadings = () => {
      const elements = Array.from(document.querySelectorAll("h2, h3, h4")).map((elem, index) => {
        if (!elem.id) {
          elem.id = `heading-${index}`;
        }
        return {
          text: elem.innerText,
          id: elem.id,
          level: parseInt(elem.tagName.substring(1)) // Extract heading level (2, 3, 4)
        };
      });
      const needed_headings = elements.slice(3,elements.length-2);
      setHeadings(needed_headings);
      setLoading(false); // Set loading to false once headings are updated
    };

    updateHeadings();

    // Observe changes in the DOM
    const observer = new MutationObserver(updateHeadings);
    observer.observe(document.body, { childList: true, subtree: true });

    return () => observer.disconnect();
  }, []);

  if (loading) return <p>Loading Table of Contents...</p>;

  const getClassName = (level) => {
    switch (level) {
      case 2: return 'head2';
      case 3: return 'head3';
      case 4: return 'head4';
      default: return null;
    }
  };

  const handleClick = (e, id) => {
    e.preventDefault();
    const element = document.querySelector(`#${id}`);
    if (element) {
      element.scrollIntoView({ behavior: "smooth" });
    }
  };

  return (
    <nav>
      <ul>
        {headings.map(heading => (
          <li key={heading.id} className={getClassName(heading.level)}>
            <a
              href={`#${heading.id}`}
              onClick={(e) => handleClick(e, heading.id)}
            >
              {heading.text}
            </a>
          </li>
        ))}
      </ul>
    </nav>
  );
}

export default TableOfContent;