// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Footer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  background-color: #333;
  color: #fff;
  padding: 20px;
}
.Links {
  display: flex;
  border-top: 2px solid #fff;
  border-bottom: 2px solid #fff;
  width: 70%;
  height: 50px;
  align-items: center;
  font-size: larger;
  justify-content: space-around;
}
.Links > span:hover {
  cursor: pointer;
  /* color: #de00a5; */
  color: rgb(33, 150, 243);
}
.social-icons {
  font-size: 2.4rem;
  width: 40%;
  margin: 1rem;
  display: flex;
  justify-content: space-around;
  align-items: center;
}
.social-icons > * {
  color: white;
}
.social-icons > *:hover {
  cursor: pointer;
  color: rgb(33, 150, 243);
  /* color: #de00a5; */
}
@media screen and (max-width: 720px) {
  .Links {
    font-size: 20px;
    border-top: 0px solid #fff;
    border-bottom: 0px solid #fff;
    width: 70%;
    height: 150px;
    flex-direction: column;
  }
}
`, "",{"version":3,"sources":["webpack://./src/components/Footer.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,sBAAsB;EACtB,mBAAmB;EACnB,8BAA8B;EAC9B,sBAAsB;EACtB,WAAW;EACX,aAAa;AACf;AACA;EACE,aAAa;EACb,0BAA0B;EAC1B,6BAA6B;EAC7B,UAAU;EACV,YAAY;EACZ,mBAAmB;EACnB,iBAAiB;EACjB,6BAA6B;AAC/B;AACA;EACE,eAAe;EACf,oBAAoB;EACpB,wBAAwB;AAC1B;AACA;EACE,iBAAiB;EACjB,UAAU;EACV,YAAY;EACZ,aAAa;EACb,6BAA6B;EAC7B,mBAAmB;AACrB;AACA;EACE,YAAY;AACd;AACA;EACE,eAAe;EACf,wBAAwB;EACxB,oBAAoB;AACtB;AACA;EACE;IACE,eAAe;IACf,0BAA0B;IAC1B,6BAA6B;IAC7B,UAAU;IACV,aAAa;IACb,sBAAsB;EACxB;AACF","sourcesContent":[".Footer {\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  justify-content: space-between;\n  background-color: #333;\n  color: #fff;\n  padding: 20px;\n}\n.Links {\n  display: flex;\n  border-top: 2px solid #fff;\n  border-bottom: 2px solid #fff;\n  width: 70%;\n  height: 50px;\n  align-items: center;\n  font-size: larger;\n  justify-content: space-around;\n}\n.Links > span:hover {\n  cursor: pointer;\n  /* color: #de00a5; */\n  color: rgb(33, 150, 243);\n}\n.social-icons {\n  font-size: 2.4rem;\n  width: 40%;\n  margin: 1rem;\n  display: flex;\n  justify-content: space-around;\n  align-items: center;\n}\n.social-icons > * {\n  color: white;\n}\n.social-icons > *:hover {\n  cursor: pointer;\n  color: rgb(33, 150, 243);\n  /* color: #de00a5; */\n}\n@media screen and (max-width: 720px) {\n  .Links {\n    font-size: 20px;\n    border-top: 0px solid #fff;\n    border-bottom: 0px solid #fff;\n    width: 70%;\n    height: 150px;\n    flex-direction: column;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
