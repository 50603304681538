import React from 'react'
import { Helmet } from 'react-helmet-async'

const SocialPreview = ({metaTitle,metaDescription,subtitle,metaUrl,metaKeywords,metaImage}) => {
    return (
        <Helmet>
            <meta name="og:title" content={metaTitle || 'writebing'} />
            <meta property="og:site_name" content="writebing"/>
            <meta name="og:description" content={metaDescription || subtitle || 'writebing'} />
            <meta name="og:url" content={metaUrl || 'https://internbuddy.in'} />
            <meta property="og:type" content="article"/>
            <meta name="keywords" content={metaKeywords || 'writebing'} />
        </Helmet>
    )
}

export default SocialPreview