import React, { useState } from 'react';
import './NewslaterPopup.css';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useStoreEmailMutation } from '../../slices/apiSlice'; // Import the mutation hook

const NewslaterPopup = ({ onClose, close }) => {
  const [email, setEmail] = useState('');
  const [storeEmail, { isLoading }] = useStoreEmailMutation();

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await storeEmail(email).unwrap(); // Use the mutation hook
      toast.success(response.message);
      
      // Delay closing the popup slightly to allow the user to see the message
      setTimeout(() => {
        onClose(email); // Close the popup after a delay
      }, 1500); // 1.5 seconds delay
    } catch (error) {
      console.error("Error storing email:", error);
      toast.error("Failed to submit it, try again after some time.");
    }
  };

  return (
    <div className="popup-overlay">
      <ToastContainer />
      
      <div className="popup">
        <span className="close-popup" onClick={() => close()}>&times;</span>
        <h2>Subscribe to our Newsletter</h2>
        <p>Stay updated with our latest news and offers.</p>
        <form onSubmit={handleSubmit}>
          <input
            type="email"
            onChange={(e) => setEmail(e.target.value)}
            placeholder="Enter your email"
            required
          />
          <button type="submit" disabled={isLoading}>Submit</button>
        </form>
      </div>
    </div>
  );
};

export default NewslaterPopup;
