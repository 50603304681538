import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    unlockedTask: []
}

export const taskSlice = createSlice({
    name: 'task',
    initialState,
    reducers:{
        addTask: (state, action)=>{
            const {ind, status} = action.payload;
            const prevTask = JSON.parse(localStorage.getItem('unlockedTask'));
            if(!prevTask){
                localStorage.setItem('unlockedTask', JSON.stringify([true]));
            }
            else{
                if(ind>=prevTask?.length){
                    prevTask.push(status);
                }
                localStorage.setItem('unlockedTask', JSON.stringify(prevTask));
            }
        }
    }
});

export const {addTask} = taskSlice.actions;
export default taskSlice.reducer;