import React, { useEffect, useRef, useState } from 'react';
import { useParams, useLocation } from 'react-router-dom';
import { useFetchBlogQuery, useFetchBlogImageQuery } from '../../slices/apiSlice';
import twitterpng from '../../img/socials/sqtwitter.png';
import { LinkedinIcon, WhatsappIcon, FacebookIcon, EmailIcon } from 'react-share';
import SEO from './SEO';
import SocialPreview from './SocialPreview';
import AuthorSEO from './AuthorSEO';
import NewslaterPopup from './NewslaterPopup';
import "react-quill/dist/quill.snow.css";
import { LazyLoadImage } from 'react-lazy-load-image-component';
import TableOfContent from './table_of_content/TableOfContent';
import Footer from '../../components/Footer';

const ShowBlog = () => {
    const [NewsLaterPopup, showNewsLaterPopup] = useState(false);
    const [popUpOnce, setPopupOnce] = useState(true);
    const [contentHeight, setContentHeight] = useState(0);

    const params = useParams();
    const location = useLocation();
    const currentUrl = `${window.location.origin}${location.pathname}`;
    const _id = extractIdFromSlug(params.slug);

    const { data: blog, isLoading: isLoadingBlog, error: blogError } = useFetchBlogQuery(_id);
    const { data: blogImage, isLoading: isLoadingImage, error: imageError } = useFetchBlogImageQuery(_id);

    const contentRef = useRef(null);

    useEffect(() => {
        if (contentRef.current && blog) {
            setContentHeight(contentRef.current.clientHeight); // Get the updated height of the content
        }
    }, [blog]);

    useEffect(() => {
        const handleScroll = () => {
            if (contentHeight > 0) {
                const totalHeight = document.documentElement.scrollHeight;
                const scrolledPercentage = (window.scrollY + window.innerHeight) / totalHeight * 100;

                if (!NewsLaterPopup && scrolledPercentage > 50) {
                    const email = localStorage.getItem("WritebingNewLaterEmail");
                    if ((!email || email.length === 0) && popUpOnce) {
                        showNewsLaterPopup(true);
                        setPopupOnce(false);
                    }
                }
            }
        };

        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, [contentHeight, NewsLaterPopup, popUpOnce]);

    const closePopup = (emailGot) => {
        showNewsLaterPopup(false);
        localStorage.setItem('WritebingNewLaterEmail', emailGot);
    };

    const onClose = () => {
        showNewsLaterPopup(false);
    };

    function extractIdFromSlug(slug) {
        const parts = slug.split('-');
        return parts[parts.length - 1];
    }

    function arrayBufferToBase64(buffer) {
        let binary = '';
        let bytes = new Uint8Array(buffer);
        bytes.forEach((b) => binary += String.fromCharCode(b));
        return window.btoa(binary);
    }

    const convertBufferToBlobUrl = (buffer, contentType) => {
        if (buffer && contentType) {
            let base64Flag = `data:${contentType};base64,`;
            let imageStr = arrayBufferToBase64(buffer.data);
            return base64Flag + imageStr;
        }
        return null;
    };

    const socialMediaData = [
        {
            platform: 'Email',
            url: `mailto:support@writebing.in`,
            icon: <EmailIcon size={32} round={true} className="social-icon" />,
        },
        {
            platform: 'Linkedin',
            url: `https://www.linkedin.com/shareArticle?mini=true&url=${encodeURIComponent(currentUrl)}`,
            icon: <LinkedinIcon size={32} round={true} className="social-icon" />,
        },
        {
            platform: 'Facebook',
            url: `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(currentUrl)}`,
            icon: <FacebookIcon size={32} round={true} className="social-icon" />,
        },
    ];

useEffect(() => {
    if (contentRef.current && blog) {
        setContentHeight(contentRef.current.clientHeight);
        console.log('Blog description:', blog.description); // Add this line to log the description
    }
}, [blog]);

    
    if (isLoadingBlog || isLoadingImage) return <div>Loading...</div>;
    if (blogError || imageError) return <div>Error loading data</div>;

    return (
        <>
            {NewsLaterPopup && <NewslaterPopup onClose={closePopup} close={onClose} />}
            <div className='show-blogPage'>
                <div className="sidebar">
                    <div className='sidebar-content'>
                        <div className='tableofcontent'>
                            <h3 className='sidebar-page-section-header'> Table of Content:</h3>
                            {blog ? (
                                <>
                                    <TableOfContent />
                                </>
                            ) : (
                                <p>Loading...</p>
                            )}
                        </div>
                        <div className='sidebar-blog-items'>
                            <ul>
                                {blog && blog.description.map((blog, index) => (
                                    <li key={index}>
                                        <a href={`#${blog.header}`} ><div className='normal' dangerouslySetInnerHTML={{ __html: blog.header }}></div></a>
                                    </li>
                                ))}
                            </ul>
                        </div>
                        <div className="sidebar-item">
                            <h4>Share this post on:</h4>
                            <hr className="divider" />
                            <div className="social-icons">
                                {socialMediaData.map((item, index) => (
                                    <a key={index} href={item.url} target="_blank" rel="noopener noreferrer">
                                        {item.icon}
                                    </a>
                                ))}
                                <a href="https://www.instagram.com/WriteBing/" target="_blank" rel="noopener noreferrer">
                                    <img src={twitterpng} alt="Instagram" className="social-icon instagram" />
                                </a>
                            </div>
                        </div>
                        <div className="sidebar-item">
                            <h4>Reach out to us:</h4>
                            <hr className="divider" />
                            <a href={`https://api.whatsapp.com/send?phone=919109264663&text=${currentUrl}`}> <WhatsappIcon size={30} className="social-icon" />WhatsApp </a>
                        </div>
                    </div>
                </div>

                {blog &&
                    <>
                        <SEO Pagetitle={blog.title} description={blog.metaDescription} keywords={blog.metaKeywords} />
                        <SocialPreview metaTitle={blog.metaTitle} metaDescription={blog.metaDescription} subtitle={blog.subtitle} metaKeywords={blog.metaKeywords} metaUrl={currentUrl} />
                        <AuthorSEO author={blog.author || 'writebing'} />
                        <div className='page-container'>
                            <div className='top-component'>
                                <h1 className='blogTitle'> {blog.title}</h1>
                                <p className='blogAuthor'>by {blog.author}, created on <strong>{new Date(blog.createdAt).toLocaleString("en-IN", { timeZone: "Asia/Kolkata" })}</strong>, last updated on <strong>{new Date(blog.updatedAt).toLocaleString("en-IN", { timeZone: "Asia/Kolkata" })}</strong>.</p>
                                <a href={currentUrl}>
                                    {
                                        blogImage &&
                                        <div className="image-container">
                                            <LazyLoadImage
                                                src={convertBufferToBlobUrl(blogImage.data, blogImage.contentType)}
                                                alt={blog.title}
                                                className="max-height-40vh"
                                            />
                                        </div>
                                    }
                                </a>
                            </div>
                            <div className="blog-post-content" ref={contentRef}>
                                {blog.description.map((description, index) => (
                                    <div key={index} id={description.header}>
                                        <div className="ql-editor" style={{ background: description.bg || '#ffffff' }} dangerouslySetInnerHTML={{ __html: description.header }}></div>
                                        <div className="ql-editor" style={{ background: description.bg || '#ffffff' }} dangerouslySetInnerHTML={{ __html: description.content }}></div>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </>
                }
            </div>
            <Footer />
        </>
    );
}

export default ShowBlog;
