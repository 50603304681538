// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `nav {
    width: 220px;
    min-width: 220px;
    padding: 16px;
    align-self: flex-start;
    position: sticky;
    /* top: 48px; */
    max-height: calc(100vh - 70px);
    overflow: auto;
    /* margin-top: 50px; */
  }
  
  nav ul li {
    margin-bottom: 15px;
  }
  /* nav {
    padding: 1rem;
    background-color: #f9f9f9;
    border: 1px solid #ddd;
  } */
  
  nav ul {
    list-style: none;
    padding: 0;
  }
  
  nav li {
    margin-bottom: 0.5rem;
  }
  
  nav a {
    text-decoration: none;
    color: #9172c2;
  }
  
  nav a:hover {
    text-decoration: underline;
  }
  
  /* Styles for different heading levels */
  .head2 {
    margin-left: 0;
    font-weight: bold;
  }
  
  .head3 {
    margin-left: 1rem;
  }
  
  .head4 {
    margin-left: 2rem;
  }
  `, "",{"version":3,"sources":["webpack://./src/pages/Blog/table_of_content/TableOfContent.css"],"names":[],"mappings":"AAAA;IACI,YAAY;IACZ,gBAAgB;IAChB,aAAa;IACb,sBAAsB;IAEtB,gBAAgB;IAChB,eAAe;IACf,8BAA8B;IAC9B,cAAc;IACd,sBAAsB;EACxB;;EAEA;IACE,mBAAmB;EACrB;EACA;;;;KAIG;;EAEH;IACE,gBAAgB;IAChB,UAAU;EACZ;;EAEA;IACE,qBAAqB;EACvB;;EAEA;IACE,qBAAqB;IACrB,cAAc;EAChB;;EAEA;IACE,0BAA0B;EAC5B;;EAEA,wCAAwC;EACxC;IACE,cAAc;IACd,iBAAiB;EACnB;;EAEA;IACE,iBAAiB;EACnB;;EAEA;IACE,iBAAiB;EACnB","sourcesContent":["nav {\n    width: 220px;\n    min-width: 220px;\n    padding: 16px;\n    align-self: flex-start;\n    position: -webkit-sticky;\n    position: sticky;\n    /* top: 48px; */\n    max-height: calc(100vh - 70px);\n    overflow: auto;\n    /* margin-top: 50px; */\n  }\n  \n  nav ul li {\n    margin-bottom: 15px;\n  }\n  /* nav {\n    padding: 1rem;\n    background-color: #f9f9f9;\n    border: 1px solid #ddd;\n  } */\n  \n  nav ul {\n    list-style: none;\n    padding: 0;\n  }\n  \n  nav li {\n    margin-bottom: 0.5rem;\n  }\n  \n  nav a {\n    text-decoration: none;\n    color: #9172c2;\n  }\n  \n  nav a:hover {\n    text-decoration: underline;\n  }\n  \n  /* Styles for different heading levels */\n  .head2 {\n    margin-left: 0;\n    font-weight: bold;\n  }\n  \n  .head3 {\n    margin-left: 1rem;\n  }\n  \n  .head4 {\n    margin-left: 2rem;\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
