import HomeIcon from '@mui/icons-material/Home';
import InfoIcon from '@mui/icons-material/Info';
import CurrencyRupeeIcon from '@mui/icons-material/CurrencyRupee';
import QuizIcon from '@mui/icons-material/Quiz';
import DashboardIcon from '@mui/icons-material/Dashboard';
import LoginIcon from '@mui/icons-material/Login';
import PersonAddAltIcon from '@mui/icons-material/PersonAddAlt';
import SyncLockIcon from '@mui/icons-material/SyncLock';
import EmojiEventsIcon from '@mui/icons-material/EmojiEvents';
import LogoutIcon from '@mui/icons-material/Logout';

import Blog from '../../../pages/Blog/BlogHome';

 const navBarItems = [
    {
        'id':0,
        'label':'Home',
        'route':'/',
        'icon':<HomeIcon/>
    },
    {
        'id':1,
        'label':'Blog',
        'route':'/blog',
        'icon':<Blog/>
    },
    {
        'id':2,
        'label':'About Us',
        'route':'/about-us',
        'icon': <InfoIcon/>
    },
    {
        'id':3,
        'label':'Buy Course',
        'route':'/payment',
        'icon':<CurrencyRupeeIcon/>
    },
    {
        'id':4,
        'label':'Play Quiz',
        'route':'/quiz',
        'icon':<QuizIcon/>

    },
    {
        'id':5,
        'label':'Digital Dashboard',
        'route':'/dashboard/digital',
        'icon':<DashboardIcon/>

    },
    {
        'id':6,
        'label':'Login',
        'route':'/login',
        'icon':<LoginIcon/>

    },
    {
        'id':7,
        'label':'Join For Free',
        'route':'/signup',
        'icon':<PersonAddAltIcon/>

    },
    {
        'id':8,
        'label':'Forgot password',
        'route':'/update-profile',
        'icon':<SyncLockIcon/>

    },
    {
        'id':9,
        'label':'Certificate',
        'route':'/certificate',
        'icon':<EmojiEventsIcon/>

    },
    {
        'id':10,
        'label':'Logout',
        'route':'/logout',
        'icon':<LogoutIcon/>

    },
    
   
]

export default navBarItems;