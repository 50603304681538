import React, { lazy, Suspense } from "react";
import ReactDOM from "react-dom/client";
import {
  createBrowserRouter,
  createRoutesFromElements,
  Route,
  RouterProvider,
} from "react-router-dom";
import { Provider } from "react-redux";
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'
import ReactGA from 'react-ga4';


import App from "./App";
import "./index.css";

// import Payment from "./pages/Payment";

// import Breakdown from "./components/Breakdown";
// import TimelineComp from "./components/TimelineComp";
import { ThemeProvider } from '@mui/material';
import theme from "./pages/ThemeProvider/ThemePage";
// import { digitalDesc, digitalOverview } from "./constants";
import store from "./store";
import BlogHome from "./pages/Blog/BlogHome";
import CreateBlog from "./pages/Blog/CreateBlog";
import ShowBlog from "./pages/Blog/ShowBlog";
import { HelmetProvider } from "react-helmet-async";
import ListAllBlog from "./pages/Blog/ListAllBlog";

const Login  = lazy(()=>import( "./pages/Login"));
const Signup = lazy(()=>import("./pages/Signup"));
const UpdatePassword = lazy(()=>import("./pages/UpdatePassword"));

const LoginSuccess = lazy(()=>import("./pages/LoginSuccess"));
const PrivateRoute =lazy(()=>import("./components/PrivateRoute"));

const Home = lazy(() => import("./pages/Home"));
// const AboutUs=lazy(()=>import( "./components/AboutUs/AboutUs"));
const ForgotPassword = lazy(() => import("./pages/ForgotPassword"));
const ResetPassword = lazy(() => import("./pages/ResetPassword"));
const PaymentStatus = lazy(() => import("./components/PaymentStatus"));
const DigitalDashboard = lazy(() => import("./pages/DigitalDashboard"));
const CourseOverview = lazy(() => import("./components/CourseOverview"));
const ContactForm = lazy(() => import("./components/ContactForm"));
const QuizApp = lazy(() => import("./pages/QuizDummy"));
// const Quiz = lazy(()=>import("./pages/Quiz"));
// const Blog =lazy(()=>import("./pages/Blog"));
const PaymentSuccess = lazy(() => import("./components/PaymentSuccess"));
const PaymentDummy = lazy(() => import("./pages/PaymentDummy"));

const PageNotFound = lazy(() => import("./pages/Page_404/PageNotFound"));
const AboutDummy = lazy(() => import("./components/AboutUs/AboutDummy"));
const CorporateDashboard = lazy(() => import("./pages/CorporateDashboard"));
const TandC = lazy(() => import("./components/TandC"));
const RefundPolicy = lazy(() => import("./components/RefundPolicy"));
const HorizontalNonLinearStepper= lazy(()=>import("./components/Certificates/Stepper"));


// const MetaDashboard =lazy(()=>import("./pages/MetaDashboard"));
const measurement_id = process.env.REACT_APP_MEASUREMENT_ID
ReactGA.initialize(measurement_id?measurement_id:'G-36E6EQ3G7D');






const router = createBrowserRouter(
  createRoutesFromElements(
    <>
      <Route 
        path="/" 
        element={
          <HelmetProvider>
            <App />
          </HelmetProvider>
        }
      >
        <Route path="" element={<PrivateRoute />}>
          <Route path="/update-profile" element={<UpdatePassword />} />
          <Route path="/certificate" element={<HorizontalNonLinearStepper />} />
          <Route path="/payment" element={<PaymentDummy />} />
          <Route path="" element={<PaymentStatus />}>
            <Route path="/dashboard/digital" element={<DigitalDashboard />} />
          </Route>
          <Route path="/quiz" element={<QuizApp />} />
          <Route path="/payment-success" element={<PaymentSuccess />} />
          <Route path="/blog" element={<BlogHome />} />
          <Route path="/blogs/:slug" element={<ShowBlog />} />
          <Route path="/create-blog" element={<CreateBlog />} />
          <Route path="/all-blog" element={<ListAllBlog />} />
          <Route path="/all-blog/:pageIndex" element={<ListAllBlog />} />
        </Route>

        <Route index={true} path="/" element={
          <Suspense fallback={<Skeleton />}>
            <Home />
          </Suspense>
        } />
        <Route path="/login" element={<Login />} />
        <Route path="/signup" element={<Signup />} />
        <Route path="term-conditions" element={<TandC />} />
        <Route path="refund-policy" element={<RefundPolicy />} />
        <Route path="Contact-form" element={<ContactForm />} />
        <Route path="about-us" element={
          <Suspense fallback={<>...</>}>
            <AboutDummy />
          </Suspense>
        } />
        <Route path="forgot-password" element={<ForgotPassword />} />
        <Route path="/password-reset/:userId/:resetToken" element={<ResetPassword />} />
        <Route path="/login-success" element={<LoginSuccess />} />
        <Route path="/meta" element={
          <Suspense fallback={<>...</>}>
            <CorporateDashboard />
          </Suspense>
        } />
        <Route path="overview-digital" element={<CourseOverview title={"Advance Digital Marketing"} />} />
      </Route>
      <Route path="*" element={<PageNotFound />} />
    </>
  )
);

const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
  <Provider store={store}>
    <React.StrictMode>
      <ThemeProvider theme={theme}>
        <RouterProvider router={router} />
      </ThemeProvider>
    </React.StrictMode>
  </Provider>
);

