import ReactGA from 'react-ga4';
const CTAButtonGATrigger = (category,action,label)=>{
  // console.log(category,action,label);
  ReactGA.event({
    category:category, //button
      action:action, //clicked
      label:label //name
  })
}


export default CTAButtonGATrigger;