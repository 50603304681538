import * as React from 'react';
import PropTypes from 'prop-types';

import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import Divider from '@mui/material/Divider';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import MenuIcon from '@mui/icons-material/Menu';

import navBarItems from '../../components/consts/navBarItems.js/navBarItems';
import { navbarStyles } from '../../components/consts/navBarItems.js/navbarStyles.js';

import Logo from "../../assets/Logo.png";
import { useNavigate } from 'react-router';
import QuizPopup from '../../components/QuizPageAdditonal/QuizPopup/quizPopup.js';
import { useDispatch, useSelector } from 'react-redux';
import { useState } from 'react';
import { useLogoutMutation, usePaymentStatusMutation } from '../../slices/apiSlice.js';
import { useEffect } from 'react';
import { toast } from 'react-toastify';
import { logout, setPaidDetails } from '../../slices/authSlice.js';
import BasicProfileMenu from './ProfileButton.js';
import CTAButtonGATrigger from '../../components/GoogleTracking/HomePage/CTAButtonGATrigger.js';
const drawerWidth = 240;
// const navItems = ['Home', 'About Us', 'Buy Course', 'Play Quiz', 'Join For Free'];

function DrawerAppBar(props) {
  const { userInfo } = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  const [paid, setPaid] = useState(false);
  const [paidAmount, setPaidAmount] = useState(0);
  const [logoutApiCall] = useLogoutMutation();
  const [paymentStatusApi] = usePaymentStatusMutation();

  const { window } = props;
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const [selectedItem, setSelectedItem] = React.useState('Home');
  const navigate = useNavigate()
  const isQuizPlayed = localStorage.getItem('quizPlayed');

  useEffect(() => {
    const fetchPaymentStatus = async () => {
      //const userInfo = JSON.parse(localStorage.getItem("userInfo"));
      const paymentStatus = JSON.parse(localStorage.getItem("paidToken"));
      const paidAmt = JSON.parse(localStorage.getItem("paidAmount"));
      if (userInfo && !paymentStatus) {
        //console.log('Under if');
        try {
          const res = await paymentStatusApi().unwrap();
          dispatch(setPaidDetails({ ...res }));
          navigate("/");
        } catch (error) {
          console.log(error.data);
          // if (error.status === 404) {
          //   console.log("Resource not found:", error.data);
          // } else {
          //   console.error(error.data);
          // }
        }
      }
      setPaid(paymentStatus);
      setPaidAmount(paidAmt);
    };
    fetchPaymentStatus();

  }, [userInfo, paid, logoutApiCall, paidAmount, dispatch, paymentStatusApi, navigate]);



  const logoutHandler = async () => {
    try {
      await logoutApiCall().unwrap();
      setPaid(false);
      dispatch(logout());
      navigate("/");
      toast.success("Successfully Logged Out!");
    } catch (err) {
      console.error(err);
    }
  };

  const items = navBarItems.filter((item) => {
    if (item.id === 3) {
      // payment page
      if (paid) return null;
    }
    else if (item.id === 4) {
      if ((isQuizPlayed === 'true') || paid) {
        return null;
      }
    }
    else if (item.id === 5) {
      if (!paid || (parseInt(paidAmount) < 79999)) {
        return null;
      }
    }
    else if (item.id >= 6 && item.id <= 7) {
      if (userInfo) return null;
    }
    else if (item.id >= 8 && item.id <= 10) {
      if (item.id === 7 && userInfo) return null;

      if (!userInfo) return null;
    }
    return item;
  })

  const handleDrawerToggle = () => {
    setMobileOpen((prevState) => !prevState);
  };

  const drawer = (
    <Box onClick={handleDrawerToggle} sx={{ textAlign: 'center' }}>

      <Box sx={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }} onClick={() => navigate("/")}>
        <Box
          component="img"
          sx={{ height: 54, marginRight: 1 }}
          alt="Logo"
          src={Logo}
        />
        <Typography variant="h6">
          InternBuddy
        </Typography>
      </Box>
      <Divider />
      <List>
        {items.map((item) => (

          <ListItem key={item.id} disablePadding>

            <ListItemButton sx={{ textAlign: 'center' }} onClick={item.id === 9 ? () => logoutHandler() : () => { navigate(item.route) }}>
              <ListItemIcon sx={navbarStyles.icons}>
                {item.icon}
              </ListItemIcon>
              <ListItemText primary={item.label} sx={navbarStyles.text} />
            </ListItemButton>
          </ListItem>

        ))}

      </List>
    </Box>
  );
  function handleClick(item) {
    // console.log("Let's call the function now,",item.label);
    CTAButtonGATrigger(
      "Button",
      "clicked",
      item.label
    )

    setSelectedItem(item);
    navigate(item.route);
  }


  // Profile 



  const container = window !== undefined ? () => window().document.body : undefined;

  return (


    <>
      <Box sx={{ display: 'flex', mb: '5rem' }}>
        <CssBaseline />
        <AppBar component="nav" color='inherit'>
          <Toolbar sx={{ justifyContent: 'space-between' }}>

            <Box
              component="img"
              sx={{ height: 54 }}
              alt="Logo"
              src={Logo}
              cursor="pointer"
              onClick={() => navigate("/")}
            />

            <Box sx={{ display: { xs: 'none', sm: 'block' } }}>
              {items.map((item) => (
                (item.id < 7) &&
                <Button key={item.id} variant={item.id === 6 ? 'outlined' : 'text'} sx={{
                  color: item === selectedItem ? '#000' : '#677B8C', backgroundColor: (item.id === 6) ? {
                    backgroundColor: '#de00a5', color: 'white', border: '1px solid rgb(255 21 229 / 50%)', '&:hover': {
                      backgroundColor: 'blue',
                    },
                  } : { backgroundColor: '#transparent' }, textTransform: 'none', fontSize: '1.15rem', fontWeight: 'normal'
                }} onClick={() => handleClick(item)}>
                  {item.label}
                </Button>

              ))}
              {/* <Button key={7} sx={{ textTransform: 'none', fontSize: '1.15rem', fontWeight: 'normal' }} onClick={() => logoutHandler()}>
              
              
                </Button> */}
              {userInfo && <BasicProfileMenu logoutHandler={logoutHandler} name={userInfo.name} />}


            </Box>

            <Box sx={{ display: { sm: 'none' } }}>
              <Button color="inherit"
                aria-label="view course" variant='contained' sx={{
                textDecoration: 'none',
                textTransform: 'none',
                color: 'white',
                backgroundColor: '#de00a5',
                padding: '0.3rem',
                '&:hover': {
                  backgroundColor: '#0000ff',
                },
                
              }} onClick={() => { navigate("/overview-digital") }}> View Course </Button>

            <IconButton
              color="inherit"
              aria-label="open drawer"
              edge="end"

            >

             



              <MenuIcon onClick={handleDrawerToggle} />
            </IconButton>
            </Box>


          </Toolbar>
        </AppBar>

        <nav>

          <Drawer
            container={container}
            variant="temporary"
            anchor="left"
            open={mobileOpen}
            onClose={handleDrawerToggle}
            ModalProps={{
              keepMounted: true, // Better open performance on mobile.
            }}
            sx={{
              display: { xs: 'block', sm: 'none' },
              '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
            }}
          >
            {drawer}
          </Drawer>
        </nav>


      </Box>
      <QuizPopup paid={paid} />
    </>


  );
}
DrawerAppBar.propTypes = {
  /**
   * Injected by the documentation to work in an iframe.
   * You won't need it on your project.
   */
  window: PropTypes.func,
};

export default DrawerAppBar;