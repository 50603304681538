// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.btn-container {
  background-color: #FFF6F6;
  padding: 8px;
  text-align: center;
  border-bottom: 2px solid darkgray;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
}

.popup-text {
  margin-right: 10px;
}

.bold-text {
  font-weight: bold;
}

.play-quiz-btn {
  text-decoration: none;
  background: #de00a5;
  padding: 6px 10px;
  border-radius: 5px;
  color: white;
  width: -moz-fit-content;
  width: fit-content;
  cursor: pointer;
  box-shadow: 0px 8px 12px rgba(0, 0, 0, 0.2);
  margin-right: 15px;
}
  
  .play-quiz-btn:hover {
    /* border: none; */
    background-color: #2541fc;
  }
  
  .close-icon{
    cursor: pointer;
  }`, "",{"version":3,"sources":["webpack://./src/components/QuizPageAdditonal/QuizPopup/quiz.css"],"names":[],"mappings":"AAAA;EACE,yBAAyB;EACzB,YAAY;EACZ,kBAAkB;EAClB,iCAAiC;EACjC,aAAa;EACb,mBAAmB;EACnB,uBAAuB;EACvB,eAAe;AACjB;;AAEA;EACE,kBAAkB;AACpB;;AAEA;EACE,iBAAiB;AACnB;;AAEA;EACE,qBAAqB;EACrB,mBAAmB;EACnB,iBAAiB;EACjB,kBAAkB;EAClB,YAAY;EACZ,uBAAkB;EAAlB,kBAAkB;EAClB,eAAe;EACf,2CAA2C;EAC3C,kBAAkB;AACpB;;EAEE;IACE,kBAAkB;IAClB,yBAAyB;EAC3B;;EAEA;IACE,eAAe;EACjB","sourcesContent":[".btn-container {\n  background-color: #FFF6F6;\n  padding: 8px;\n  text-align: center;\n  border-bottom: 2px solid darkgray;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  flex-wrap: wrap;\n}\n\n.popup-text {\n  margin-right: 10px;\n}\n\n.bold-text {\n  font-weight: bold;\n}\n\n.play-quiz-btn {\n  text-decoration: none;\n  background: #de00a5;\n  padding: 6px 10px;\n  border-radius: 5px;\n  color: white;\n  width: fit-content;\n  cursor: pointer;\n  box-shadow: 0px 8px 12px rgba(0, 0, 0, 0.2);\n  margin-right: 15px;\n}\n  \n  .play-quiz-btn:hover {\n    /* border: none; */\n    background-color: #2541fc;\n  }\n  \n  .close-icon{\n    cursor: pointer;\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
