import React, { useState } from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

const BlogHeader = ({ descriptions, index, setDescriptions }) => {
  const modules = { toolbar: [[{ 'header': [1, 2, 3, 4, 5, false] }], [{ 'align': [] }]] };
  const formats = ['header', 'align'];

  const handleUpdate = (text) => {
    const arr_cpy = descriptions.slice();
    if (arr_cpy[index]) {
      arr_cpy[index].header = text;
      setDescriptions(arr_cpy);
    }
  };

  return (
    <div style={{margin:'0.4em'}}>
        
      <ReactQuill
        value={descriptions[index]?.header || ''}
        onChange={handleUpdate}
        modules={modules}
        formats={formats}
        placeholder='Add your section header here'
        theme="snow"
      />
    </div>
  );
};

export default BlogHeader;
