// InternalServerError.js
import React from 'react';
import './InternalServerError.css'; // Import the CSS file

const InternalServerError = () => {
  return (
    <div className="error-page">
      <h1>500</h1>
      <p>Internal Server Error</p>
      <button onClick={() => window.location.reload()}>Try Again</button>
    </div>
  );
};

export default InternalServerError;
