// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* Login.css */

.login-container {
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #f0f0f0;
  }
  
  .login-form {
    background-color: #fff;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
    width: 300px;
    text-align: center;
  }
  
  .login-form h2 {
    margin-bottom: 20px;
  }
  
  .login-form input {
    width: calc(100% - 40px);
    margin: 10px 0;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 16px;
  }
  
  .login-form button {
    width: calc(100% - 40px);
    margin-top: 20px;
    padding: 10px;
    background-color: #007bff;
    color: #fff;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 16px;
    transition: background-color 0.3s ease;
  }
  
  .login-form button:hover {
    background-color: #0056b3;
  }
  `, "",{"version":3,"sources":["webpack://./src/pages/Blog/loginPage.css"],"names":[],"mappings":"AAAA,cAAc;;AAEd;IACI,aAAa;IACb,aAAa;IACb,uBAAuB;IACvB,mBAAmB;IACnB,yBAAyB;EAC3B;;EAEA;IACE,sBAAsB;IACtB,aAAa;IACb,kBAAkB;IAClB,2CAA2C;IAC3C,YAAY;IACZ,kBAAkB;EACpB;;EAEA;IACE,mBAAmB;EACrB;;EAEA;IACE,wBAAwB;IACxB,cAAc;IACd,aAAa;IACb,sBAAsB;IACtB,kBAAkB;IAClB,eAAe;EACjB;;EAEA;IACE,wBAAwB;IACxB,gBAAgB;IAChB,aAAa;IACb,yBAAyB;IACzB,WAAW;IACX,YAAY;IACZ,kBAAkB;IAClB,eAAe;IACf,eAAe;IACf,sCAAsC;EACxC;;EAEA;IACE,yBAAyB;EAC3B","sourcesContent":["/* Login.css */\n\n.login-container {\n    height: 100vh;\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    background-color: #f0f0f0;\n  }\n  \n  .login-form {\n    background-color: #fff;\n    padding: 20px;\n    border-radius: 8px;\n    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);\n    width: 300px;\n    text-align: center;\n  }\n  \n  .login-form h2 {\n    margin-bottom: 20px;\n  }\n  \n  .login-form input {\n    width: calc(100% - 40px);\n    margin: 10px 0;\n    padding: 10px;\n    border: 1px solid #ccc;\n    border-radius: 4px;\n    font-size: 16px;\n  }\n  \n  .login-form button {\n    width: calc(100% - 40px);\n    margin-top: 20px;\n    padding: 10px;\n    background-color: #007bff;\n    color: #fff;\n    border: none;\n    border-radius: 4px;\n    cursor: pointer;\n    font-size: 16px;\n    transition: background-color 0.3s ease;\n  }\n  \n  .login-form button:hover {\n    background-color: #0056b3;\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
