import React, { useState } from 'react'
import './createBlogPage.css';
// import Editor from './Editor/Editor';
import QuillEditor from './QuillEditor';
import BlogHeader from './BlogHeader';
import Preview from './Preview';

const FormCreateBlog = ({ PageTitle, ButtonTitle, handleTitleChange, handleMetaTitle, handleSubTitleChange, handleAuthorChange, handleMetaDescription, handleMetaUrl, handleKeyWords, handleImageChange, descriptions, index, setDescriptions, editing, blogHeaderValue }) => {
    const [preview, showPreview] = useState(false);
    function addSection() {
        const arr_cpy = descriptions.slice();
        arr_cpy.push({ 'header': '', 'content': '' });
        setDescriptions(arr_cpy);
    }
    function removeSection(index) {
        const arr_cpy = descriptions.slice();
        arr_cpy.splice(index, 1); // Removes the element at the specified index
        setDescriptions(arr_cpy);
    }


    return (
        <>
            <button className="button-submit" onClick={() => showPreview(!preview)}> Preview</button>
            {!preview &&
                <div className='blog-container'>

                    <div className='left-section'>
                        <div className='page-title'>
                            <h3>{PageTitle || 'Write your new Blog'}
                            </h3></div>

                        <div className='sections-option'>
                            {/* <div className='add-section-title btn' onSubmit={() => setshowBlogHeaderOption(true)}>Add title</div> */}

                            <form className='form-section' >
                                <input
                                    type="text"
                                    className="form-control"
                                    placeholder="Add Blog's Title "
                                    name='section-title'
                                    required={true}
                                    value={blogHeaderValue.title}
                                    onChange={handleTitleChange}

                                />
                                <input
                                    type="text"
                                    className="form-control"
                                    placeholder="Add Author"
                                    name='section-author'
                                    value={blogHeaderValue.author}
                                    required
                                    onChange={handleAuthorChange}
                                />
                                <textarea
                                    type="text"
                                    className="form-control"
                                    placeholder="Add Blog's Subtitle"
                                    name='section-subtitle'
                                    required
                                    value={blogHeaderValue.subtitle}
                                    onChange={handleSubTitleChange}

                                />
                                <input
                                    type="text"
                                    className="form-control"
                                    placeholder="Add Meta Title"
                                    name='section-meta-title'
                                    value={blogHeaderValue.metaTitle}
                                    required
                                    onChange={handleMetaTitle}

                                />
                                <input
                                    type="text"
                                    className="form-control"
                                    placeholder="Add Meta Description"
                                    name='section-meta-description'
                                    value={blogHeaderValue.metaDescription}
                                    required
                                    onChange={handleMetaDescription}
                                />

                                <input
                                    type="text"
                                    className="form-control"
                                    placeholder="Add Meta url"
                                    name='section-meta-url'
                                    value={blogHeaderValue.metaUrl}
                                    required
                                    onChange={handleMetaUrl}
                                />

                                <input
                                    type="text"
                                    className="form-control"
                                    placeholder="Add Keywords"
                                    name='section-keywords'
                                    value={blogHeaderValue.metaKeywords}
                                    required
                                    onChange={handleKeyWords}
                                />

                                <label htmlFor='section-image'>Add Image  </label>
                                <input
                                    type="file"
                                    className="form-control"
                                    placeholder="Blog Image"
                                    name='section-image'
                                    required
                                    onChange={(e) => handleImageChange(e)}
                                />

                                <button type="button" className='button-add-section' onClick={() => addSection()}> + </button>

                                {/* <Editor value={description} setValue={setDescription} /> */}
                                {
                                    descriptions.map((description, index) => {
                                        return (
                                            <div key={index}>
                                                <form>
                                                    <BlogHeader descriptions={descriptions} index={index} setDescriptions={setDescriptions} />
                                                    <QuillEditor descriptions={descriptions} index={index} setDescriptions={setDescriptions} />
                                                    <button type="button" className='button-submit' onClick={() => removeSection(index)}> - </button>

                                                </form>
                                            </div>
                                        );
                                    })
                                }



                                {/* <button className='btn btn-remove' type='submit' onClick={(e) => handleSubmit(e)} disabled={sections.length}> RemoveBlog Your Blog </button> */}


                            </form>


                        </div>
                    </div>
                    <div className='right-section'>
                        <h3>Preview Blog</h3>
                        <Preview
                            blog={blogHeaderValue}
                            descriptions={descriptions}
                            editing={editing}
                        />
                    </div>
                </div>}
            {preview &&
               <Preview
                            blog={blogHeaderValue}
                            descriptions={descriptions}
                            editing={editing}
                        />
            }
        </>

    )
}

export default FormCreateBlog