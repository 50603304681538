// SkeletonScreen.js
import React from 'react';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';

const SkeletonScreen = () => {
  return (
    <div style={{ padding: '20px' }}>
      <Skeleton height={40} width={`80%`} />
      <Skeleton height={20} width={`60%`} style={{ marginTop: '10px' }} />
      <Skeleton height={20} width={`90%`} style={{ marginTop: '10px' }} />
      <Skeleton height={20} width={`50%`} style={{ marginTop: '10px' }} />
      <Skeleton height={300} width={`100%`} style={{ marginTop: '20px' }} />
    </div>
  );
};

export default SkeletonScreen;
