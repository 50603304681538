import React, { useEffect, useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import './allBlog.css';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import createSlug from './createSlug';
import Footer from '../../components/Footer';
import { useGetAllBlogsQuery, useGetTotalBlogsQuery } from '../../slices/apiSlice'; // Import the hooks

const ListAllBlog = () => {
  const { pageIndex: paramPageIndex } = useParams();
  const navigate = useNavigate();
  const [pageIndex, setPageIndex] = useState(Number(paramPageIndex) || 1);
  const pageSize = 5;

  // Use RTK Query hook to fetch all blogs
  const { data: blogsData, isLoading: blogsLoading, error: blogsError } = useGetAllBlogsQuery(pageIndex);

  // Use RTK Query hook to fetch the total number of blogs
  const { data: totalBlogsData, isLoading: totalLoading, error: totalError } = useGetTotalBlogsQuery();

  const totalPages = totalBlogsData ? Math.ceil(totalBlogsData.totalBlogs / pageSize) : 0;

  // Convert array buffer to base64
  function arrayBufferToBase64(buffer) {
    let binary = '';
    let bytes = new Uint8Array(buffer);
    bytes.forEach((b) => binary += String.fromCharCode(b));
    return window.btoa(binary);
  }

  // Convert buffer to Blob URL
  const convertBufferToBlobUrl = (buffer, contentType) => {
    if (buffer && contentType) {
      let base64Flag = `data:${contentType};base64,`;
      let imageStr = arrayBufferToBase64(buffer.data);
      return base64Flag + imageStr;
    }
    return null;
  };

  // Handle pagination change
  const handlePageChange = (newPageIndex) => {
    if (newPageIndex >= 1 && newPageIndex <= totalPages) {
      setPageIndex(newPageIndex);
      navigate(`/all-blog/${newPageIndex}`);
    }
  };

  if (blogsLoading || totalLoading) return <p>Loading...</p>;
  if (blogsError || totalError) return <p>Error fetching blogs</p>;

  return (
    <>
      <div className="all-blog-container">
        <h2>All Blogs</h2>
        <div className="blog-list">
          {blogsData.message.map((blog) => (
            <div key={blog._id} className="blog-item">
              {blog.image && (
                <LazyLoadImage
                  src={convertBufferToBlobUrl(blog.image.data, blog.image.contentType)}
                  alt={blog.title}
                  className="blog-image"
                />
              )}
              <div className="blog-content">
                <Link to={`/blogs/${createSlug(blog.title, blog._id)}`}>
                  <h3>{blog.title}</h3>
                  <p>{blog.subtitle || 'No subtitle'}</p>
                </Link>
              </div>
            </div>
          ))}
        </div>

        <div className="pagination">
          <button onClick={() => handlePageChange(pageIndex - 1)} disabled={pageIndex <= 1}>
            Prev
          </button>
          <button onClick={() => handlePageChange(pageIndex + 1)} disabled={totalPages && pageIndex >= totalPages}>
            Next
          </button>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default ListAllBlog;
