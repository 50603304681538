import React from "react";
import "./Footer.css";
import { Link } from "react-router-dom";
import { LinkContainer } from "react-router-bootstrap";
//import Payment from "../assets/Payment.png";
// import Instamojo from "../assets/Instamojo-logo.png";
import { FaInstagram, FaLinkedin, FaTwitter, FaYoutube } from "react-icons/fa";

const Footer = () => {
  return (
    <div className="Footer">
      <div className="social-icons">
        <Link to="https://www.instagram.com/intern_buddy/">
          <FaInstagram />
        </Link>
        <Link to="https://twitter.com/Intern_Buddy">
          <FaTwitter />
        </Link>
        <Link to="https://www.youtube.com/channel/UCNd3Ulp-AfXsHXCPZJ_egfw">
          <FaYoutube />
        </Link>
       <Link to='https://www.linkedin.com/company/intern-buddy/'>
          <FaLinkedin />
        </Link>
      </div>
      <div className="Links">
        <LinkContainer to="/about-us">
          <span>About us</span>
        </LinkContainer>
        <LinkContainer to="/term-conditions">
          <span>Term and Conditions</span>
        </LinkContainer>
        <LinkContainer to="/refund-policy">
          <span>Refund Policy</span>
        </LinkContainer>
        <LinkContainer to="/Contact-form">
          <span>Contact Us</span>
        </LinkContainer>
        <LinkContainer to="/blog">
          <span>Blog</span>
        </LinkContainer>
      </div>
      <h2 className="my-4">Payment Partner</h2>
      <a href="https://razorpay.com/" target="_blank" rel="noopener noreferrer">
        <img
          referrerPolicy="origin"
          src="https://badges.razorpay.com/badge-light.png"
          style={{ height: 45, width: 113 }}
          alt="Razorpay | Payment Gateway | Neobank"
        />
      </a>
      {/* <img
        src={Instamojo}
        className="img-fluid"
        style={{ width: 70, height: 70 }}
        alt="payment-partner"
      /> */}
      <div className="contact-info mt-4">support@internbuddy.in</div>
      <div className="contact-info mt-1">Raipur, Chattisgarh,491001, IN</div>
      
      
      
      
          
        
      
      
      
      <p className="my-4">© Copyright 2024 @Qodeit</p>
    </div>
  );
};

export default Footer;
