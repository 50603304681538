// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* CustomQuill.css */

.create_blog .ql-editor {
    padding: 15px;
    /* border: 1px solid #ccc; */
    border-radius: 8px;
    box-shadow: 2px;
    max-height: 80vh;
    overflow: auto;
   
}

.ql-toolbar {
    border: 1px solid #ccc;
    border-bottom: none;
    border-radius: 8px 8px 0 0;
    background-color: #fff;
    padding: 10px;
}
`, "",{"version":3,"sources":["webpack://./src/pages/Blog/Beautify.css"],"names":[],"mappings":"AAAA,oBAAoB;;AAEpB;IACI,aAAa;IACb,4BAA4B;IAC5B,kBAAkB;IAClB,eAAe;IACf,gBAAgB;IAChB,cAAc;;AAElB;;AAEA;IACI,sBAAsB;IACtB,mBAAmB;IACnB,0BAA0B;IAC1B,sBAAsB;IACtB,aAAa;AACjB","sourcesContent":["/* CustomQuill.css */\n\n.create_blog .ql-editor {\n    padding: 15px;\n    /* border: 1px solid #ccc; */\n    border-radius: 8px;\n    box-shadow: 2px;\n    max-height: 80vh;\n    overflow: auto;\n   \n}\n\n.ql-toolbar {\n    border: 1px solid #ccc;\n    border-bottom: none;\n    border-radius: 8px 8px 0 0;\n    background-color: #fff;\n    padding: 10px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
