import React, { useState } from 'react';
import {Link} from 'react-router-dom';
import './quiz.css';
import CloseIcon from '@mui/icons-material/Close';
import CTAButtonGATrigger from '../../GoogleTracking/HomePage/CTAButtonGATrigger';


function QuizPopup({paid}) {
    const [showPopup, setShowPopup] = useState(true);
  const handlePopup = ()=>{
    CTAButtonGATrigger("Button","clicked","Play Quiz PopUp");
    setShowPopup(false);
  }
  return (
    <>
    {window.location.pathname === '/' && 
      !JSON.parse(localStorage.getItem('quizPlayed')) && 
      !paid && 
      showPopup && 
      <div className='btn-container'>
        <span className='popup-text'>
          Play Quiz to win exciting discounts on our 
          <span className='bold-text'> Advanced Digital Marketing Course. </span> 
        </span>
        <Link to={'/quiz'} className="play-quiz-btn" onClick={() => handlePopup()}>
          Test Your Knowledge 
        </Link> <span>
        <CloseIcon className='close-icon' onClick={handlePopup} /> </span>
      </div>
    }
  </>
  );
  }
  

  export default QuizPopup;