import React, { useState } from 'react';
import './loginPage.css'; // Import your custom CSS file
import { ToastContainer, toast } from 'react-toastify';
import { useLogInCreateBlogMutation } from '../../slices/apiSlice'; // Import the mutation hook

const LoginPage = ({ setLogIn }) => {
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    
    // Hook to handle the login mutation
    const [logInCreateBlog, { isLoading }] = useLogInCreateBlogMutation();

    const handleLogin = async (e) => {
        e.preventDefault();

        try {
            const res = await logInCreateBlog({ username, password }).unwrap(); // Call the mutation
            if (res && res.message === "Correct credentials") {
                toast.success("You have successfully logged in");
                setLogIn(true); // Set login state to true
            } else {
                toast.warning("Invalid Credentials");
            }
            
            // Reset the fields after a successful login
            setUsername('');
            setPassword('');
        } catch (error) {
            toast.error("Error 500, Failed to reach the server! Try again.");
            console.error("Login error: ", error);
        }
    };

    return (
        <>
            <ToastContainer />
            <div className="login-container">
                <form className="login-form" onSubmit={handleLogin}>
                    <h2>Login</h2>
                    <input
                        type="text"
                        placeholder="Username"
                        value={username}
                        onChange={(e) => setUsername(e.target.value)}
                        required
                    />
                    <input
                        type="password"
                        placeholder="Password"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        required
                    />
                    <button type="submit" disabled={isLoading}>
                        {isLoading ? 'Logging in...' : 'Login'}
                    </button>
                </form>
            </div>
        </>
    );
};

export default LoginPage;
