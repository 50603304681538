// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.popup-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 9999;
}

.popup {
  background: white;
  padding: 20px;
  border-radius: 10px;
  position: relative;
  width: 400px;
  max-width: 90%;
  text-align: center;
  box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.1);
}

.close-popup {
  position: absolute;
  top: 10px;
  right: 10px;
  font-size: 24px;
  cursor: pointer;
  color: #aaa;
  transition: color 0.2s ease-in-out;
}

.close-popup:hover {
  color: #000;
}

.popup h2 {
  margin: 0 0 15px;
  font-size: 24px;
}

.popup p {
  margin-bottom: 20px;
}

.popup form input {
  width: calc(100% - 20px);
  padding: 10px;
  margin-bottom: 10px;
  border-radius: 5px;
  border: 1px solid #ccc;
}

.popup form button {
  padding: 10px 20px;
  border: none;
  background-color: #007BFF;
  color: white;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.2s ease-in-out;
}

.popup form button:hover {
  background-color: #0056b3;
}
`, "",{"version":3,"sources":["webpack://./src/pages/Blog/NewslaterPopup.css"],"names":[],"mappings":"AAAA;EACE,eAAe;EACf,MAAM;EACN,OAAO;EACP,WAAW;EACX,YAAY;EACZ,8BAA8B;EAC9B,aAAa;EACb,mBAAmB;EACnB,uBAAuB;EACvB,aAAa;AACf;;AAEA;EACE,iBAAiB;EACjB,aAAa;EACb,mBAAmB;EACnB,kBAAkB;EAClB,YAAY;EACZ,cAAc;EACd,kBAAkB;EAClB,2CAA2C;AAC7C;;AAEA;EACE,kBAAkB;EAClB,SAAS;EACT,WAAW;EACX,eAAe;EACf,eAAe;EACf,WAAW;EACX,kCAAkC;AACpC;;AAEA;EACE,WAAW;AACb;;AAEA;EACE,gBAAgB;EAChB,eAAe;AACjB;;AAEA;EACE,mBAAmB;AACrB;;AAEA;EACE,wBAAwB;EACxB,aAAa;EACb,mBAAmB;EACnB,kBAAkB;EAClB,sBAAsB;AACxB;;AAEA;EACE,kBAAkB;EAClB,YAAY;EACZ,yBAAyB;EACzB,YAAY;EACZ,kBAAkB;EAClB,eAAe;EACf,6CAA6C;AAC/C;;AAEA;EACE,yBAAyB;AAC3B","sourcesContent":[".popup-overlay {\n  position: fixed;\n  top: 0;\n  left: 0;\n  width: 100%;\n  height: 100%;\n  background: rgba(0, 0, 0, 0.5);\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  z-index: 9999;\n}\n\n.popup {\n  background: white;\n  padding: 20px;\n  border-radius: 10px;\n  position: relative;\n  width: 400px;\n  max-width: 90%;\n  text-align: center;\n  box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.1);\n}\n\n.close-popup {\n  position: absolute;\n  top: 10px;\n  right: 10px;\n  font-size: 24px;\n  cursor: pointer;\n  color: #aaa;\n  transition: color 0.2s ease-in-out;\n}\n\n.close-popup:hover {\n  color: #000;\n}\n\n.popup h2 {\n  margin: 0 0 15px;\n  font-size: 24px;\n}\n\n.popup p {\n  margin-bottom: 20px;\n}\n\n.popup form input {\n  width: calc(100% - 20px);\n  padding: 10px;\n  margin-bottom: 10px;\n  border-radius: 5px;\n  border: 1px solid #ccc;\n}\n\n.popup form button {\n  padding: 10px 20px;\n  border: none;\n  background-color: #007BFF;\n  color: white;\n  border-radius: 5px;\n  cursor: pointer;\n  transition: background-color 0.2s ease-in-out;\n}\n\n.popup form button:hover {\n  background-color: #0056b3;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
