import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  userInfo: localStorage.getItem("userInfo")
    ? JSON.parse(localStorage.getItem("userInfo"))
    : null,
};

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    setCredentials: (state, action) => {
      state.userInfo = action.payload;
      localStorage.setItem("userInfo", JSON.stringify(action.payload));
      localStorage.setItem("token", JSON.stringify(action.payload.accessToken));
    },
    setPaidDetails: (state, action) => {
      localStorage.setItem(
        "paidToken",
        JSON.stringify(action.payload.paymentStatus)
      );
      localStorage.setItem(
        "paidAmount",
        JSON.stringify(action.payload.paidAmount)
      );
    },
    logout: (state, action) => {
      state.userInfo = null;
      localStorage.removeItem("userInfo");
      localStorage.removeItem("token");
      localStorage.removeItem("paidToken");
      localStorage.removeItem("paidAmount");
      localStorage.removeItem("quizPlayed");
    },
  },
});

export const { setCredentials, logout, setPaidDetails } = authSlice.actions;
export default authSlice.reducer;
