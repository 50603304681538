import { createTheme } from '@mui/material/styles';
const theme = createTheme({
    typography: {
      fontFamily: [
        '"Apple Color Emoji"',
        'BlinkMacSystemFont',
        '"Segoe UI Emoji"',
        'sans-serif',
        'Arial',
        '"Segoe UI"',
        '-apple-system',
        '"Helvetica Neue"',
        '"Segoe UI Symbol"',
        'Roboto',
          
          
          
      ].join(','),
    },
    
  });

  export default theme;