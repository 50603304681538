
import  { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import ReactGA from 'react-ga4';

// Initialize ReactGA with your GA4 measurement ID
// Replace with your GA4 measurement ID

const HomePagehit = () => {
  const location = useLocation();

  useEffect(() => {
    const title = document.title || 'Untitled Page'; // Fallback to 'Untitled Page' if document.title is not set
    // console.log("Making the request",title,location)
    ReactGA.send({
      hitType: 'pageview',
      page: location.pathname + location.search,
      title: title,
    });
  }, [location]);

  return null; // This component does not render anything
};

export default HomePagehit;
