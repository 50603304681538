import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    unlockedVideo : []
};

export const videoSlice = createSlice({
    name: 'video',
    initialState,
    reducers:{
        addVideo: (state, action)=>{
            const {ind, status} = action.payload;
            const prevVideo = JSON.parse(localStorage.getItem('unlockedVideo'));
            if(!prevVideo){
                localStorage.setItem('unlockedVideo', JSON.stringify([true,true]));
            }
            else{
                if(ind>=prevVideo?.length-1){
                    prevVideo.push(status);
                }
                localStorage.setItem('unlockedVideo', JSON.stringify(prevVideo));
            }
        }
    }
});

export const {addVideo} = videoSlice.actions;
export default videoSlice.reducer;