import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

const baseQuery = fetchBaseQuery({
  // baseUrl: "http://localhost:8080",
  //baseUrl: "https://backend-qodeit.onrender.com",
  //  baseUrl: "https://api.internbuddy.in",
  baseUrl:process.env.REACT_APP_BACKEND_URL,
});

export const apiSlice = createApi({
  baseQuery,
  endpoints: (builder) => ({
    signup: builder.mutation({
      query: (user) => ({
        url: "/api/auth/signup",
        method: "POST",
        body: user,
      }),
    }),

    login: builder.mutation({
      query: (user) => ({
        url: "/api/auth/login",
        method: "POST",
        body: user,
      }),
    }),
    updatePassword: builder.mutation({
      query: (user) => {
        const item = JSON.parse(localStorage.getItem("token"));
        // console.log(item);
        return {
          url: "/api/auth/updatePassword",
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${item}`,
          },
          body: user,
        };
      },
    }),

    logout: builder.mutation({
      query: () => ({
        url: "/api/auth/logout",
        method: "POST",
      }),
    }),

    certificateReq: builder.mutation({
      query: (data) => {
        const item = JSON.parse(localStorage.getItem("token"));
        return {
          url: "/api/ask/certificate",
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${item}`,
          },
          body: data,
        };
      },
    }),

    paymentHandle: builder.mutation({
      query: (body) => {
        console.log(body);
        const item = JSON.parse(localStorage.getItem("token"));
        console.log(item);
        return {
          url: "/api/pay/payment",
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${item}`,
          },
          body: body,
        };
      },
    }),
    checkoutHandle: builder.mutation({
      query: (body) => {
        console.log(body);
        const item = JSON.parse(localStorage.getItem("token"));
        console.log(item);
        return {
          url: "/api/pay/create-order",
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${item}`,
          },
          body: body,
        };
      },
    }),

    paymentVerification: builder.mutation({
      query: (body) => {
        // console.log(body);
        const item = JSON.parse(localStorage.getItem("token"));
        return {
          url: "/api/pay/verifyPayment",
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${item}`,
          },
          body: body,
        };
      },
    }),

    paymentStatus: builder.mutation({
      query: (body) => {
        const item = JSON.parse(localStorage.getItem("token"));
        return {
          url: "/api/pay/payment-status",
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${item}`,
          },
          body: body,
        };
      },
    }),

    forgotPassword: builder.mutation({
      query: (data) => ({
        url: "/api/auth/forgot-password",
        method: "POST",
        body: data,
      }),
    }),
    resetPassword: builder.mutation({
      query: (data) => {
        const { newPassword, userId, resetToken } = data;
        return {
          url: `/api/auth/reset-password/${userId}/${resetToken}`,
          method: "POST",
          body: {newPassword},
        };
      },
    }),
    contactUs: builder.mutation({
      query: (data) => ({
        url: "/api/contact/contact-us",
        method: "POST",
        body: data,
      }),
    }),
    uploadTask: builder.mutation({
      query: (data) => {
        const item = JSON.parse(localStorage.getItem("token"));
        return {
          url: "/api/task/upload",
          method: "POST",
          headers: {
            Authorization: `Bearer ${item}`,
          },
          body: data,
        };
      },
    }),
    setQuizPlayed: builder.mutation({
      query: () => {
        const item = JSON.parse(localStorage.getItem("token"));
        return {
          url: "/api/misc/quiz",
          method: "POST",
          headers: {
            Authorization: `Bearer ${item}`,
          },
        };
      },
    }),
    setQuizDummyPlayed: builder.mutation({
      query: (data) => {
        const item = JSON.parse(localStorage.getItem("token"));
        
        return {
          url: "/api/misc/calculate-quiz",
          method: "POST",
          headers: {
            Authorization: `Bearer ${item}`,
          },
          body:data
        };
      },
    }),
    getQuizPlayed: builder.mutation({
      query: () => {
        const item = JSON.parse(localStorage.getItem("token"));
        return {
          url: "/api/misc/quiz",
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${item}`,
          },
        };
      },
    }),
    setDummyPayment: builder.mutation({
      query: (paymentId) => {
        const item = JSON.parse(localStorage.getItem("token"));
        return {
          url: "/api/pay/d-payment",
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${item}`,
          },
          body:JSON.stringify({ payment_id: paymentId })
        };
      },
    }),
    gethasCheckContact: builder.mutation({
      query: () => {
        const item = JSON.parse(localStorage.getItem("token"));
        return {
          url: "/api/auth/get-contact-info",
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${item}`,
          },
          
        };
      },
    }),
    getCertificateSatus: builder.mutation({
      query: () => {
        const item = JSON.parse(localStorage.getItem("token"));
        return {
          url: "/api/ask/get-certificate-status",
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${item}`,
          },
          
        };
      },
    }),
    sethasCheckContact: builder.mutation({
      query: (phone) => {
        const item = JSON.parse(localStorage.getItem("token"));
        return {
          url: "/api/auth/set-contact-info",
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${item}`,
          },
          body: JSON.stringify({phone:phone})
          
        };
      },
    }),

////////////////////////////////////////////////////deepesh slice/////////////////////////////////////////////////////
    getTopBlog: builder.query({
      query: () => ({
        url: "/api/blog/get-top-blog",
        method: "GET",
      }),
    }),

     // New Upload API slice
 uploadBlog: builder.mutation({
  query: (formData) => ({
    url: "/api/blog/upload",
    method: "POST",
    body: formData, // This will contain the form data including files
    headers: {
      // Important for file upload in multipart form-data
      "Content-Type": "multipart/form-data",
    },
  }),
}),


  // Get paginated blogs API slice
getAllBlogs: builder.query({
  query: (pageIndex) => ({
    url: `/api/blog/get-all-blogs/${pageIndex}`,
    method: "GET",
  }),
}),

    // New slice to get total blogs count
    getTotalBlogs: builder.query({
      query: () => ({
        url: "/api/blog/total-blogs",
        method: "GET",
      }),
    }),

      // New login and create blog mutation
      logInCreateBlog: builder.mutation({
        query: (credentials) => ({
          url: "/api/blog/log-in-create-blog",
          method: "POST",
          body: credentials,  // username and password sent here
        }),
      }),

          // New Store Email API slice
    storeEmail: builder.mutation({
      query: (email) => ({
        url: "/api/blog/store-email",
        method: "POST",
        body: { email },
      }),
    }),
    // Fetch a specific blog post
    fetchBlog: builder.query({
      query: (id) => `/api/blog/get-blog/${id}`,
    }),

    // Fetch the image of a specific blog post
    fetchBlogImage: builder.query({
      query: (id) => `/api/blog/get-blog-image/${id}`,
    }),


  }),
});


export const {
  useLoginMutation,
  useSignupMutation,
  useUpdatePasswordMutation,
  useLogoutMutation,
  useCertificateReqMutation,
  usePaymentHandleMutation,
  useCheckoutHandleMutation,
  usePaymentVerificationMutation,
  useContactUsMutation,
  useForgotPasswordMutation,
  useResetPasswordMutation,
  usePaymentStatusMutation,
  useUploadTaskMutation,
  useGetQuizPlayedMutation,
  useSetQuizPlayedMutation,
  useSetQuizDummyPlayedMutation,
  useSetDummyPaymentMutation,
  useGethasCheckContactMutation,
  useGetCertificateSatusMutation,
  useSethasCheckContactMutation,





/////////////////////////// deepesh/////////////////////////// 
  useGetTopBlogQuery,
  useUploadBlogMutation,
  useGetAllBlogsQuery,
  useGetTotalBlogsQuery,
  useLogInCreateBlogMutation,
  useStoreEmailMutation, 
  useFetchBlogQuery,
  useFetchBlogImageQuery,
} = apiSlice;
export default apiSlice;
