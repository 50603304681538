import React, { useCallback } from 'react';
import { Link } from 'react-router-dom';
import './blogHome.css';
import { toast } from 'react-toastify';
import InternalServerError from './page_500_/InternalServerError';
import createSlug from './createSlug';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import Footer from '../../components/Footer';
import { useGetTopBlogQuery } from "../../slices/apiSlice"

const BlogHome = () => {
  const { data, error, isLoading } = useGetTopBlogQuery();  // Using the hook to fetch blog data

  const arrayBufferToBase64 = useCallback((buffer) => {
    let binary = '';
    let bytes = new Uint8Array(buffer);
    bytes.forEach((b) => binary += String.fromCharCode(b));
    return window.btoa(binary);
  }, []);

  const convertBufferToBlobUrl = useCallback((buffer, contentType) => {
    if (buffer && contentType) {
      let base64Flag = `data:${contentType};base64,`;
      let imageStr = arrayBufferToBase64(buffer.data);
      return base64Flag + imageStr;
    }
    return null;
  }, [arrayBufferToBase64]);

  if (isLoading) {
    return <p>Loading...</p>;
  }

  if (error) {
    toast.error("Error 500, Failed to reach the server");
    return <InternalServerError />;
  }

  const featuredBlog = data?.topBlog;
  const blogTitleList = data?.nextFiveBlogs || []; // Ensure this is always an array

  return (
    <>
      {error && <InternalServerError />}
      {!error && (
        <div className="page-container">
          <h2 className="blog-page-header"> Our Top Blogs </h2>
          <div className="blog-home-container">
            <div className="left-half">
              {featuredBlog && (
                <div className="featured-blog">
                  <h2 className="title-feature-blog">{featuredBlog.title}</h2>
                  <p className="subtitle-feature-blog">{featuredBlog.subtitle.substring(0, 100) + "..." || "..."}</p>
                  <p className="view-more">
                    <Link to={`/blogs/${createSlug(featuredBlog.title, featuredBlog._id)}`}> read more...</Link>
                  </p>
                  <div className="image-container">
                    {featuredBlog.image && (
                      <a href={`/blogs/${createSlug(featuredBlog.title, featuredBlog._id)}`}>
                        <LazyLoadImage
                          src={convertBufferToBlobUrl(featuredBlog.image.data, featuredBlog.image.contentType)}
                          alt={featuredBlog.title}
                        />
                      </a>
                    )}
                  </div>
                  <div className="">
                    <Link to="/all-blog">
                      <button className="blog-button">View all Blogs</button>
                    </Link>
                  </div>
                </div>
              )}
            </div>
            <div className="right-half">
              <h2 className="recent-blog-header">Recent Blogs</h2>
              {blogTitleList.length > 0 ? (
                blogTitleList.slice(0, 5).map((blogList) => (
                  <Link key={blogList._id} to={`/blogs/${createSlug(blogList.title, blogList._id)}`}>
                    <div className="blog-item">
                      <h3>{blogList.title}</h3>
                      <div className="blog-item-subtitle">
                        <p>{blogList.subtitle || blogList.title}</p>
                        <p className="view-detail">Click to read more</p>
                      </div>
                    </div>
                  </Link>
                ))
              ) : (
                <p>No recent blogs available</p>
              )}
            </div>
          </div>
        </div>
      )}
      <Footer />
    </>
  );
};


export default BlogHome;
