import React, { Suspense,lazy } from 'react';
import { Outlet } from "react-router";
// import Navigation from "./components/Navigation";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./App.css";
import SkeletonScreen from "./pages/skeleton/homePage";
import NavBarDummy from './pages/Navbar/NavBarDummy.js';
import "bootstrap/dist/css/bootstrap.min.css";
// import Footer from './components/Footer.js';
import ScrollTracker from './components/GoogleTracking/HomePage/ScrollTracker.js';
import HomePagehit from './components/GoogleTracking/HomePage/HomePageHit.js';
const ContactUsPopUp =lazy(()=>import( "./components/ContactUsPopUp"));

function App() {
  
  return (
    <>
      
      <ScrollTracker/>
      <HomePagehit/>
      <NavBarDummy/>
      
      
      {/* <Navigation /> */}
      <ContactUsPopUp />
      <ToastContainer />
      <Suspense fallback={<SkeletonScreen />}>
        <Outlet />
      </Suspense>
      {/* <Footer/> */}
    </>
  );
}



export default App;
