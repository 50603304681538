
// CustomQuill.js
import React, { useState ,useMemo,useRef} from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import Quill from 'quill';
import './CustomQuill.css';
import './fontEdit.css';
import './Beautify.css'; // Import the custom CSS
import QuillResizeImage from 'quill-resize-image';

// Define your custom sizes
const Size = Quill.import('attributors/style/size');
Size.whitelist = ['10px', '12px', '14px', '16px', '18px', '20px', '24px', '30px', '36px'];
Quill.register(Size, true);

const Font = Quill.import('formats/font');
Font.whitelist = ['arial', 'times-new-roman', 'verdana', 'roboto', 'courier-new', 'helvetica', 'Impact', 'georgia', 'tahoma', 'trebuchet-ms']; // Most widely used fonts
Quill.register(Font, true);

 
Quill.register('modules/resize', QuillResizeImage);

// Custom background color format
const BackgroundStyle = Quill.import('attributors/style/background');
Quill.register(BackgroundStyle, true);

// Custom toolbar options
const toolbarOptions = [
  [{ header: [1, 2, 3, 4, 5, 6, false] }],
  
  [{ 'size': ['10px', '12px', '14px', '16px', '18px', '20px', '24px', '30px', '36px'] }],
  ['bold', 'italic', 'underline', 'strike','blockquote',"code-block"],
  [{ 'list': 'ordered' }, { 'list': 'bullet' },{ indent: "-1" },
    { indent: "+1" }],
    [{ color: [] }],
  [{ 'align': [] }],
  [{ 'script': 'super' }, { 'script': 'sub' }],
  [{ 'font': ['arial', 'times-new-roman', 'verdana', 'roboto', 'courier-new', 'helvetica', 'Impact', 'georgia', 'tahoma', 'trebuchet-ms'] }],
  ['link', 'image'],
  [{ 'color': [] },{ 'background': [] }], // Background color picker
  ['customBackground'] ,// Custom background color picker button
  ['hr'] // Custom background color picker button
  
];

const CustomQuill = ({ value, onChange,bg,setBg }) => {

  // Memoize modules to avoid re-creation on each render
  const quillRef = useRef(null);
  const modules = useMemo(() => ({
    toolbar: {
      container:toolbarOptions,
      handlers: {
        customBackground: () => {
          const input = document.createElement('input');
          input.type = 'color';
          input.addEventListener('input', () => {
            const color = input.value;
            
            if (color) {
              setBg(color);
              
              const editor = quillRef.current.getEditor();
              editor.root.style.backgroundColor = color;
              
              
            }
            
              
          });
          input.click(); // Open color picker dialog
          
          
        },
        hr: () => {
          const editor = quillRef.current.getEditor();
          const range = editor.getSelection();
          if (range) {
            editor.insertEmbed(range.index, 'hr', true, Quill.sources.USER);
          }
        }

      }
    },
    
    
    resize: {
      locale: {},
    },
    
    
    
  }), [setBg]);

  return (

    <ReactQuill
     ref={quillRef}
      value={value}
      onChange={onChange}
      modules={modules}
      placeholder='Add your description here'
      theme="snow"
    />
  );
};


// App.js



function QuillEditor({descriptions,index,setDescriptions}) {
  // const [value, setValue] = useState('');
  const [bg, setBg] = useState('#ffffff');

  function handleUpdate(text){
    // console.log(index,text,typeof(text));
    const arr_cpy = descriptions.slice();
    if(arr_cpy[index]){
      arr_cpy[index].content=text;
      setDescriptions(arr_cpy);
    }
  }

  return (
    <div className="create_blog">
      
      <CustomQuill value={descriptions[index].content} onChange={(text)=>{handleUpdate(text)}} bg={bg} setBg={setBg}/>
      {/* <div className="output">
        <h2>Output</h2>
    <div className="ql-editor"style={{background:bg}} dangerouslySetInnerHTML={{ __html: value }} />
    {value}
    {bg}
       
      </div> */}
    </div>
  );
}

export default QuillEditor;

