import React, { useState } from 'react';
import './createBlogPage.css';
import { useNavigate } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import FormCreateBlog from './FormCreateBlog';
import LoginPage from './LoginPage';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { useUploadBlogMutation } from '../../slices/apiSlice'; 

const CreateBlog = () => {
    const [blogHeaderValue, setBlogHeaderValue] = useState({
        title: '', subtitle: '', author: '', metaTitle: '', metaDescription: '', metaUrl: '', keywords: '', image: null
    });
    const [descriptions, setDescriptions] = useState([{ header: '', content: '' }]);
    const [loggedIn, setLoggedIn] = useState(false);
    const [scheduledTime, setScheduledTime] = useState(null); // For scheduling
    const [isScheduled, setIsScheduled] = useState(false);   // Toggle for scheduling
    const navigate = useNavigate();

    // Mutation hook from the slice
    const [uploadBlog, { isLoading }] = useUploadBlogMutation(); 

    // Handlers for input changes
    const handleInputChange = (e, field) => {
        setBlogHeaderValue(prevState => ({ ...prevState, [field]: e.target.value }));
    };

    const handleImageChange = (event) => {
        setBlogHeaderValue(prevState => ({ ...prevState, image: event.target.files[0] }));
    };

    const handleScheduledTimeChange = (date) => {
        console.log("Scheduled Time Changed:", date); // Log the selected date
        setScheduledTime(date);
    };

    const handleScheduleToggle = () => {
        setIsScheduled(!isScheduled);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
    
        // Frontend validation for scheduled time
        if (isScheduled && scheduledTime && new Date(scheduledTime) <= new Date()) {
            toast.warning("Please select a future time for scheduling.");
            return;  // Prevent form submission
        }
    
        try {
            const formData = new FormData();
            formData.append('title', blogHeaderValue.title);
            formData.append('subtitle', blogHeaderValue.subtitle);
            formData.append('author', blogHeaderValue.author);
            formData.append('image', blogHeaderValue.image);
            formData.append('metaTitle', blogHeaderValue.metaTitle);
            formData.append('metaDescription', blogHeaderValue.metaDescription);
            formData.append('metaUrl', blogHeaderValue.metaUrl);
            formData.append('metaKeywords', blogHeaderValue.keywords);
            formData.append('description', JSON.stringify(descriptions));
            formData.append('scheduledTime', isScheduled && scheduledTime ? scheduledTime.toISOString() : ''); // Handle null or empty string
 
            // Use the slice mutation instead of axios
            const res = await uploadBlog(formData).unwrap(); 
            console.log(res); // Log response to ensure it's working
    
            if (res) {
                const message = isScheduled ? "Blog Scheduled Successfully!" : "Blog Posted Immediately!";
                toast.success(message);  // Show success notification
                navigate("/blog");
            } else {
                toast.warning("Error while posting the blog! Try again later.");
            }
        } catch (error) {
            toast.error("Internal Server Error! Try again later.");
            console.error("Failed to upload blog:", error);
        }
    };
    
    return (
        <>
            <ToastContainer />  {/* Toast container to handle all toast notifications */}

            {!loggedIn && <LoginPage setLogIn={setLoggedIn} />}

            {loggedIn && (
                <>
                    <FormCreateBlog
                        PageTitle="Add your New Blog"
                        ButtonTitle={isScheduled ? "Schedule Blog" : "Post Blog Now"}
                        handleTitleChange={(e) => handleInputChange(e, 'title')}
                        handleMetaTitle={(e) => handleInputChange(e, 'metaTitle')}
                        handleSubTitleChange={(e) => handleInputChange(e, 'subtitle')}
                        handleAuthorChange={(e) => handleInputChange(e, 'author')}
                        handleMetaDescription={(e) => handleInputChange(e, 'metaDescription')}
                        handleMetaUrl={(e) => handleInputChange(e, 'metaUrl')}
                        handleKeyWords={(e) => handleInputChange(e, 'keywords')}
                        handleImageChange={handleImageChange}
                        descriptions={descriptions}
                        setDescriptions={setDescriptions}
                        handleSubmit={handleSubmit}
                        blogHeaderValue={blogHeaderValue}
                        editing={false}
                    />

                    <div className="schedule-section">
                        <label>
                            <input type="checkbox" checked={isScheduled} onChange={handleScheduleToggle} />
                            Schedule Blog
                        </label>

                        {isScheduled && (
                            <DatePicker
                                selected={scheduledTime}
                                onChange={handleScheduledTimeChange}
                                showTimeSelect
                                dateFormat="Pp"
                                placeholderText="Select a date and time"
                            />
                        )}
                    </div>

                    <button className='button-submit' type='submit' onClick={handleSubmit} disabled={isLoading}>
                        {isScheduled ? 'Schedule Blog' : 'Post Blog Now'}
                    </button>
                </>
            )}

        </>
    );
};

export default CreateBlog;
