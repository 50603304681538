import React from 'react'
import { Helmet } from 'react-helmet-async'

const AuthorSEO = ({author}) => {
  return (
    <Helmet>
            <meta name="og:article:author" content={author || 'writebing'} />
            <meta property="og:article:section" content="Research writebing"/>
            <meta name="og:article:tag" content={['section']} />
            
        </Helmet>
  )
}

export default AuthorSEO