import React, { useEffect, useState } from 'react'
import { LazyLoadImage } from 'react-lazy-load-image-component'
import './showBlogPost.css';


const Preview = ({blog,descriptions,bg,editing}) => {

    function arrayBufferToBase64(buffer) {
        let binary = '';
        let bytes = new Uint8Array(buffer);
        bytes.forEach((b) => binary += String.fromCharCode(b));
        return window.btoa(binary);
    };
    const convertBufferToBlobUrl = (buffer, contentType) => {

        // console.log("Buffere",buffer.data)
        if (buffer && contentType) {
            let base64Flag = `data:${contentType};base64,`;
            let imageStr = arrayBufferToBase64(buffer.data);
            const img = base64Flag + imageStr;
            // console.log("Image after conversion:", img);
            return img;
        }
        return null;
    };
    
    const [fileUrl, setFileUrl] = useState(null);
    useEffect(() => {
        if (!editing && blog.image) {
            const reader = new FileReader();
            reader.onloadend = () => {
                setFileUrl(reader.result);
            };
            reader.readAsDataURL(blog.image);
        }
        if(editing && blog.image){
            setFileUrl(convertBufferToBlobUrl(blog.image.data, blog.image.contentType));
        }
        console.log(blog.image);
    }, [blog.image]);

    return (
        <div className='page-container'>
            <div className='top-component'>
                <h1 className='blogTitle'> {blog.title}</h1>
                <p className='blogAuthor'>{blog.author}</p>
                <p className='blogSubtitle'>{blog?.subtitle || ''}</p>

                
                {fileUrl && (
                    <LazyLoadImage
                        src={fileUrl}
                        alt={blog.title}
                        className="blog-image"
                    />
                )}
                
            </div>



            <div
                // style={{height:contentHeight}}

                className="blog-post-content"

            // dangerouslySetInnerHTML={{ __html: blog.description }}
            >
                {descriptions.map((description, index) => (
                    <div key={index}>
                        <div className="ql-editor" style={{ background: bg || '#ffffff' }} dangerouslySetInnerHTML={{ __html: description.header }}></div>
                        <div className="ql-editor" style={{ background: bg || '#ffffff' }} dangerouslySetInnerHTML={{ __html: description.content }}></div>
                    </div>
                    
                ))}
                

            </div>







        </div>
    )
}

export default Preview