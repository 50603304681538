import * as React from 'react';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import { Avatar,Divider } from '@mui/material';
import { useNavigate } from 'react-router';

export default function BasicMenu({logoutHandler,name}) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  
  const toCapitalCase = (str) => {
    return str.replace(/\b\w/g, (char) => char.toUpperCase());
    };
  const capitalizedUsername = toCapitalCase(name);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const navigate = useNavigate();

  return (
        <>
      <Button
        id="basic-button"
        aria-controls={open ? 'basic-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        sx={{ textTransform: 'none', fontSize: '1.15rem', fontWeight: 'normal' }}
        onClick={handleClick}
      >
        <Avatar sx={{bgcolor:'blue'}} alt='User'><AccountCircleIcon /></Avatar>
      </Button>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
            'aria-labelledby': 'basic-button',
        }}
        >
        <MenuItem><AccountCircleIcon />  {capitalizedUsername}</MenuItem>
      <Divider />
        <MenuItem onClick={()=>navigate('/update-profile')}>Change Password</MenuItem>
        <MenuItem onClick={()=>navigate('/certificate')}>Certificate</MenuItem>
        <MenuItem onClick={logoutHandler}>Logout</MenuItem>
      </Menu>
      </>
    
  );
}
